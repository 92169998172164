import React, { useState } from 'react';
import './AndroidAppTesting.css';
import surfjournalcaptures from '../images/surfjournalcaptures.png';
import sessiondetailsportrait from '../images/sessiondetailsportrait.png';
import mysessionspixel8 from '../images/mysessionspixel8.png';
import newsessionpixel8 from '../images/newsessionpixel8.png';
import sessiondetailspixel8 from '../images/sessiondetailspixel8.png';
import today from '../images/today.png';
import tmrw from '../images/tmrw.png' 
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Collapse, Button, Form, Input, notification } from 'antd';
import Parse from 'parse/dist/parse.min.js';

const { Panel } = Collapse;

const faqData = [
    {
      question: 'How much does it cost?',
      answer: 'It\'s free. Future versions with advanced functionality may require a subscription, but all early adopters who provide feedback will get Surf Journal free for life. To join the test group please register by filling the form above.'
    },
    {
      question: 'When will Android be available?',
      answer: 'Timing is dependent on having enough test users. If you\'d like to help speed up the process please register by filling the form above.'
    },
    {
      question: 'How can I get shacked more often?',
      answer: 'We love getting shacked, tubed, barreled, and generally spending time in the green room. We log our sessions to help us get better at knowing when and where the waves are on... and to savor the epic sessions. While we can\'t help you get shacked, we will be there for you to log it when you do! '
    },
  ];

const AndroidAppTesting = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const Subscriber = Parse.Object.extend('Subscriber');
    const subscriber = new Subscriber();

    subscriber.set('name', name);
    subscriber.set('email', email);
    subscriber.set('type', 'Android Tester');

    try {
      await subscriber.save();
      notification.success({
        message: 'Thank you!',
        description: 'You are now subscribed for Android testing updates.',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    }

    setName('');
    setEmail('');
  };

  return (
    <div className="homepage">
      
      <HeroSection 
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        handleSubmit={handleSubmit}
      />
      
      <FAQ />
      <CallToAction />
    </div>
  );
};

const Header = () => (
  <header className="header">
    <p>What up gangstaaa?</p>
  </header>
);

const HeroSection = ({ name, setName, email, setEmail, handleSubmit }) => (
  <section className="hero-section-android">
    <img src={sessiondetailsportrait} alt="Digital Surf Journal" className="hero-image responsive-image" />
    <div className="hero-text-content">
      <h1>Android is on the way...</h1>
      <div className="hero-section-description">
        <h3>Join as a beta tester for early access.</h3>
        <p>All testers will receive lifetime access to Surf Journal for free.</p>
      </div>
      <div className="hero-section-subscribe">
        <form 
          onSubmit={handleSubmit}
          className="subscribe-form"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item className="form-item">
            <Input.TextArea 
              placeholder="Name" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              required
            />
          </Form.Item>
          <Form.Item className="form-item">
            <Input.TextArea 
              placeholder="Email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required
            />
          </Form.Item>
          <Form.Item className="form-item button-item" style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">
              Join Android Testing
            </Button>
          </Form.Item>
        </form>
      </div>
    </div>
  </section>
);

const Explainer = () => (
  <section className="explainer">
    <div className="explainer-container">
      <div className="explainer-video">
        <ReactPlayer
          url="https://youtu.be/uXr-0j90UCQ"
          controls={true}
          width="100%"
          height="auto"
        />
      </div>
      <div className="explainer-text">
        <h2>How it works</h2>
        <p>Log your surf sessions.</p>
        <p>Evaluate conditions.</p>
        <p>Keep notes.</p>
      </div>
    </div>
  </section>
);

const CoreBenefits = () => (
  <section className="core-benefits">
    <div className="benefits">
      <div className="benefit benefit-left">
        <img src={newsessionpixel8} alt="Surf Journal Log New Session" />
        <div className="text">
            <h2>Log A New Session</h2>
            <p>Record your session in less than a minute.</p>
        </div>
      </div>
      <div className="benefit benefit-right">
        <img src={sessiondetailspixel8} alt="Surf Journal - Session Details Entry" />
        <div className="text">
            <h2>View Session Details</h2>
            <p>Images, notes, conditions, and more.</p>
        </div>
      </div>
      <div className="benefit benefit-left">
        <img src={mysessionspixel8} alt="Surf Journal My Sessions" />
        <div className="text">
            <h2>View History of Sessions</h2>
            <p>Look back on previous sessions.</p>
        </div>
      </div>
    </div>
  </section>
);

const FAQ = () => (
  <section className="faq-section">
    <h2>More questions...</h2>
    <Collapse accordion>
      {faqData.map((faq, index) => (
        <Panel header={faq.question} key={index}>
          <p>{faq.answer}</p>
        </Panel>
      ))}
    </Collapse>
  </section>
);

const CallToAction = () => (
  <section className="call-to-action">
    <h3>Join</h3>
    <p>Get notified when new features are launched.</p>
  </section>
);

export default AndroidAppTesting;