import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useAuth } from './useAuth';
import './HeaderStyles.css';

const { Header } = Layout;

const AppHeader = () => {
  const [current, setCurrent] = useState(null);
  const navigate = useNavigate();
  const { currentUser, doUserLogOut } = useAuth();

  const handleLogout = async () => {
    await doUserLogOut(); // Perform logout
    navigate('/login'); // Redirect to login page
  };

  const handleClick = e => {
    setCurrent(e.key); // Update the state when a menu item is clicked
  };

  useEffect(() => {
    // Update current state based on the current path
    const path = window.location.pathname;

    if (path.startsWith('/mysessions')) {
      setCurrent('2');
    } else if (path.startsWith('/mybreaks')) {
      setCurrent('3');
    } else if (path.startsWith('/addboard')) {
      setCurrent('4');
    } else {
      switch (path) {
        case '/newsession':
          setCurrent('1');
          break;
        case '/myboards':
          setCurrent('4');
          break;
        case '/login':
          setCurrent('6');
          break;
        case '/register':
          setCurrent('7');
          break;
        default:
          setCurrent('1');
          break;
      }
    }
  }, [window.location.pathname]);

  return (
    <Header style={{ position: 'fixed', zIndex: 10, width: '100%', backgroundColor: '#ffffff' }}>
      <Menu
        theme="light"
        mode="horizontal"
        style={{ backgroundColor: '#ffffff', fontSize: '18px' }}
        onClick={handleClick}
        selectedKeys={[current]}
      >
        <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
          <img src="./images/surf-journal-logo.png" alt="Logo" style={{ height: '40px', marginRight: '16px' }} />
          <span className="brand-name">Surf Journal</span>
        </Link>
        
        {/* 
        
        
        OLD 
          >> Login / Register when no user
          >> New Session / My Sessions / My Breaks / My Boards when user is logged in




        {currentUser ? (
          <>
            <Menu.Item key="1">
              <Link to="/newsession">New Session</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/mysessions">My Sessions</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/mybreaks">My Breaks</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/myboards">My Boards</Link>
            </Menu.Item>
            <Menu.Item key="5" style={{ marginLeft: 'auto', fontSize: '14px' }} onClick={handleLogout}>
              Logout
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item key="6" style={{ marginLeft: 'auto' }}>
              <Link to="/login">Login</Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/register">Register</Link>
            </Menu.Item>
          </>
        )}
        */}
      </Menu>
    </Header>
  );
};

export default AppHeader;
