import React from 'react';
import './HomePage.css'; 
import surfjournalcaptures from '../images/surfjournalcaptures.png';
import mysessionspixel8 from '../images/mysessionspixel8.png';
import newsessionpixel8 from '../images/newsessionpixel8.png';
import sessiondetailspixel8 from '../images/sessiondetailspixel8.png';
import today from '../images/today.png';
import tmrw from '../images/tmrw.png' 
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Subscribe from '../Components/Subscribe';
import { Collapse } from 'antd';

const { Panel } = Collapse;




const HomePage = () => {
 
return (
    <div className="homepage">
      
      <HeroSection />
      <Explainer />
      <CoreBenefits />
      <FAQ />
      <CallToAction />
    </div>
  );
};

const Header = () => (
  <header className="header">
    <h3>Beta Version</h3>
    <p>Thank you for your feedback</p>
  </header>
);

const HeroSection = () => (
  <section className="hero-section">
    <img src={surfjournalcaptures} alt="Digital Surf Journal" className="hero-image" />
    <div className="hero-text-content">
      <h1>Surf Journal</h1>
      <div className="hero-section-description">
        <h3>Session tracking & forecasting</h3>
        <p>Track your surf sessions with photos, notes, weather data, and ratings.</p>
      </div>
      <div className="hero-section-button">
      <Link to="https://apps.apple.com/us/app/surf-journal-app/id6504604479">
          <button className="secondary-btn">iOS</button>
        </Link>
        <Link to="/androidapp">
          <button className="secondary-btn">Android</button>
        </Link>
      </div>
    </div>
  </section>
);

const Explainer = () => (
  <section className="explainer">
    <div className="explainer-container">
      <div className="explainer-video">
        <ReactPlayer
          url="https://youtube.com/shorts/-F7pkgZbkaI"
          controls={true}
          width="100%"
          maxWidth="360px"
          height="auto"
        />
      </div>
      <div className="explainer-text">
        <h2>How it works</h2>
        <p>Log your surf sessions.</p>
        <p>Evaluate conditions.</p>
        <p>Browse past sessions.</p>
      </div>
    </div>
  </section>
);

const CoreBenefits = () => (
  <section className="core-benefits">
    <div className="benefits">
      <div className="benefit benefit-left">
        <img src={newsessionpixel8} alt="Surf Journal Log New Session" />
        <div className="text">
            <h2>Log A New Session</h2>
            <p>Record your session in less than a minute.</p>
        </div>
      </div>
      <div className="benefit benefit-right">
        <img src={sessiondetailspixel8} alt="Surf Journal - Session Details Entry" />
        <div className="text">
            <h2>View Session Details</h2>
            <p>Images, notes, conditions, and more.</p>
        </div>
      </div>
      <div className="benefit benefit-left">
        <img src={mysessionspixel8} alt="Surf Journal My Sessions" />
        <div className="text">
            <h2>View History of Sessions</h2>
            <p>Look back on previous sessions.</p>
        </div>
      </div>
    </div>
  </section>
);

const FAQ = () => (
  <section className="faq-section">
    <h2>More questions...</h2>
    <Collapse accordion>
      {faqData.map((faq, index) => (
        <Panel header={faq.question} key={index}>
          <p>
            {faq.answer}
            {index === 2 && (
              <Link to="/androidapp"> Android App Testing</Link>
            )}
          </p>
        </Panel>
      ))}
    </Collapse>
  </section>
);

const CallToAction = () => (
  <section className="call-to-action">
    <h3>Want Updates?</h3>
    <p>Get notified when new features are launched.</p>
    <Subscribe />
  </section>
);



const faqData = [
  {
    question: 'How much does it cost?',
    answer: 'It\'s free. Future versions with advanced functionality may require a subscription, but all early adopters who provide feedback will get Surf Journal free for life. If you\'d like to join our test group please download the app and submit a question on the Settings page to let us know you\'d like to join the test group.'
  },
  {
    question: 'How do I provide my feedback?',
    answer: 'Navigate to the Settings page within the app.'
  },
  {
    question: 'When will Surf Journal be ready for Android?',
    answer: 'The Android test version is currently in beta. If you\'d like to get early access please register here:'
  },
];







export default HomePage;
